(() => {
	if (window.dsfDropper) {
		console.error('[Dropper] This script has already been loaded. Please load it only once.')
	}

	const loadedServices: string[] = []

	// Define dsfApp public methods
	if (!window.dsfApp) {
		window.dsfApp = document.createElement('div')
	}

	window.dsfApp.open = function(application: string) {
		window.dsfApp.dispatchEvent(
			new CustomEvent('openDsfApp', { detail: { application }}),
		)
	}

	window.dsfApp.addEventListener('onReady', (detail: { application: string }) => {
		window.dsfApp.open(detail.application)
	})

	// Define dsfDropper public methods
	window.dsfDropper = {
		openDsfService: async (application?: string, options?: { forceOpening?: boolean }) => {
			const dsfAppElement = getServiceTag(application)

			const params: Record<string, string> = {
				application: dsfAppElement.getAttribute('application') || '',
				tenant: dsfAppElement.getAttribute('tenant') || '',
				customer: dsfAppElement.getAttribute('customer') || '',
				country: dsfAppElement.getAttribute('country') || '',
				touchpoint: dsfAppElement.getAttribute('touchpoint') || '',
				environment: dsfAppElement.getAttribute('environment') || '',
				type: dsfAppElement.getAttribute('type') || '',
			}

			if (!loadedServices.includes(params.application)) {
				const useNamespacedTag = dsfAppElement.tagName.toLowerCase() !== 'dsf-app'
				const dsfAppFileUrl = await getServiceFile(params, useNamespacedTag)

				if (await loadServiceScript(dsfAppFileUrl)) {
					loadedServices.push(params.application)

					if (application) {
						window.dsfApp.open(application)
					}
				} else {
					throw new Error('Could not load service file.')
				}
			} else if (options?.forceOpening) {
				window.dsfApp.open(application)
			}

			return application || params.application
		},
	}

	/**
	 * Get service tag (e.g <core-app>) or <dsf-app> tag from the DOM
	 */
	const getServiceTag = (application?: string): Element => {
		// Get service tag
		const serviceTag = document.querySelector(`${application}-app`)

		if (serviceTag) {
			return serviceTag
		} else {
			// Get all <dsf-app> tags
			const dsfTags = document.querySelectorAll('dsf-app')

			if (dsfTags.length === 0) {
				console.error(`[Dropper] Unable to find any <${application}-app> or <dsf-app> tag in your DOM.`)

				throw new Error('')
			}

			if (application) {
				const el = Array.from(dsfTags).find(el => el.getAttribute('application') === application)

				if (!el) {
					console.error(`[Dropper] Unable to find ${application} <${application}-app> or <dsf-app> tag in your DOM.`)

					throw new Error('')
				}

				debugLog(`<${application}-app> or <dsf-app> tag for application ${application} found.`)

				return el
			} else {
				if (dsfTags.length > 1) {
					console.warn(`[Dropper] There are more than one <${application}-app> or <dsf-app> tag in your DOM. The first one has been taken.`)
				}

				return dsfTags[0]
			}
		}
	}

	/**
	 * Get <dsf-app> tag script URL by using fetch API
	 */
	const getServiceFile = async (params: Record<string, string>, useNamespacedTag: boolean) => {
		const url = new URL('https://firecamp.modiface.com/api/dropper')

		for (const [key, value] of Object.entries(params)) {
			url.searchParams.set(key, value)
		}

		debugLog('Get service URL with parameters.', params)

		try {
			const fetchResponse = await fetch(url, {
				method: 'get',
				headers: {
					'Content-Type': 'application/json',
				},
			})

			if (fetchResponse.ok) {
				const jsonResponse = await fetchResponse.json()

				debugLog('Service URL has been found.')

				if (useNamespacedTag) {
					return jsonResponse.dsfServiceUrl
				} else {
					return jsonResponse.dsfAppUrl
				}
			}

			throw new Error(`Could not find service file you are looking for. Please check your attributes: ${JSON.stringify(params)}`)
		} catch (err) {
			console.error(`[Dropper] ${err}`)

			throw err
		}
	}

	/**
	 * Inject passed script URL into DOM
	 */
	const loadServiceScript = async (scriptUrl: string) => {
		return await new Promise((resolve) => {
			const script = document.createElement('script')
			script.type = 'text/javascript'
			script.src = scriptUrl

			script.onerror = (err) => {
				console.error('[Dropper] Could not load service file.', err)

				resolve(false)
			}

			script.onload = () => {
				setTimeout(() => {
					resolve(true)
				})
			}

			debugLog('Add script to your DOM.')
			document.head.appendChild(script)
		})
	}

	/**
	 * Debug function
	 */
	const isDebug = (new URL(document.location.toString())).searchParams.has('debug')
	const debugLog = (...args: any[]) => {
		if (isDebug) {
			console.warn('[Dropper]', ...args)
		}
	}

	async function autoLoad() {
		const application = await window.dsfDropper.openDsfService()

		window.dsfApp.dispatchEvent(
			new CustomEvent('onLoadComplete', { detail: { application }}),
		)
	}

	// Dropper auto-run strategy
	if (document.currentScript?.getAttribute('auto-run') !== 'false') {
		debugLog('You have choosen the auto-run strategy.')

		debugLog('document.readyState ' + document.readyState)
		if (document.readyState === 'interactive' || document.readyState === 'complete' || document.readyState === 'loading') {
			// interactive
			setTimeout(() => {
				// eslint-disable-next-line @typescript-eslint/no-floating-promises
				autoLoad()
			}, 1)
		} else {
			debugLog('wait for DOMContentLoaded')
			// eslint-disable-next-line @typescript-eslint/no-misused-promises
			document.addEventListener('DOMContentLoaded', autoLoad, false)
		}
	} else {
		debugLog('You have not choosen the auto-run strategy.')
	}

	// Dropper load callback
	if (typeof window.dsfDropperLoaded === 'function') {
		window.dsfDropperLoaded()
	}
})()
